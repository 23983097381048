import React from 'react';
import PropTypes from 'prop-types';
import './VendorCard.css';

const VendorCard = ({ name, description, imageUrl, onClick }) => {
  return (
    <div className="vendor-card" onClick={onClick}>
      <img 
        src={imageUrl || 'vendor-placeholder.jpg'} 
        alt={name} 
        className="vendor-card-image" 
      />
      <div className="vendor-card-content">
        <h3 className="vendor-card-name">{name}</h3>
        <p className="vendor-card-description">{description}</p>
      </div>
    </div>
  );
};

VendorCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func, // Optional click handler for navigation
};

export default VendorCard;
