import { Route, Routes, Link} from 'react-router-dom';
import HomePage from './screens/HomePage'; 
import MapScreen from './screens/MapScreen'; 
import ContactScreen from './screens/ContactScreen';
import BecomeVendorScreen from './screens/BecomeVendorScreen';
import StoreListings from './components/Vendor/StoreListings'; 
import './styles/App.css';

function App() {

  return (
    <div className="app-container">
      {/* Navbar */}
      <nav className="navbar">
        <Link to="/" className="navbar-logo">CompraSi</Link>
        <ul className="navbar-links">
          <li><Link to="/">Inicio</Link></li>
          <li><Link to="/map">Mapa</Link></li>
          <li><Link to="/contact">Contacto</Link></li>
        </ul>
        <Link to="/become-vendor" className="vendor-button">
          Vendedor
        </Link>
      </nav>

      {/* Routes */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/map" element={<MapScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/become-vendor" element={<BecomeVendorScreen />} />
        <Route path="/store/:id" element={<StoreListings />} />
      </Routes>
    </div>
  );
}

export default App;
