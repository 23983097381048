import React, { useState } from 'react';
import '../styles/BecomeVendorScreen.css';

const countryCodes = [
  { code: '+502', country: 'Guatemala', phoneLength: 8 },
  { code: '+1', country: 'Estados Unidos', phoneLength: 10 },
  { code: '+54', country: 'Argentina', phoneLength: 10 },
  { code: '+55', country: 'Brasil', phoneLength: 11 },
  { code: '+57', country: 'Colombia', phoneLength: 10 },
  { code: '+52', country: 'México', phoneLength: 10 },
  { code: '+91', country: 'India', phoneLength: 10 },
  // Agregar más países si es necesario
];

const BecomeVendorScreen = () => {
  const [selectedCode, setSelectedCode] = useState('+502'); // Código de país predeterminado
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Estado del menú desplegable
  const [phoneNumber, setPhoneNumber] = useState(''); // Estado del número de teléfono
  const [storeName, setStoreName] = useState(''); // Nombre de la tienda
  const [description, setDescription] = useState(''); // Descripción de la tienda
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado de envío

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCodeSelect = (code) => {
    setSelectedCode(code);
    setIsDropdownOpen(false);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Permitir solo números
    const selectedCountry = countryCodes.find((country) => country.code === selectedCode);

    // Limitar la longitud del número de teléfono según el formato del país seleccionado
    if (value.length <= selectedCountry.phoneLength) {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validación de campos requeridos
    if (!storeName || !description || !phoneNumber) {
      alert('Por favor, complete todos los campos obligatorios.');
      return;
    }

    // Validación de la longitud del número de teléfono
    const selectedCountry = countryCodes.find((country) => country.code === selectedCode);
    if (phoneNumber.length !== selectedCountry.phoneLength) {
      alert(
        `El número de teléfono debe tener ${selectedCountry.phoneLength} dígitos para ${selectedCountry.country}.`
      );
      return;
    }

    setIsSubmitting(true);

    // Generar URL de WhatsApp
    const whatsappNumber = `12087602524`; // Reemplazar con el número de WhatsApp oficial
    const message = `Hola, soy ${storeName}.\nDescripción: ${description}\nNúmero: ${selectedCode}${phoneNumber}`;

    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

    // Redirigir a WhatsApp
    window.open(whatsappURL, '_blank');
    setIsSubmitting(false);
  };

  return (
    <div className="become-vendor-screen">
      <h1>Regístrate como Vendedor</h1>
      <form className="vendor-form" onSubmit={handleSubmit}>
        <label htmlFor="store-name">Nombre de la Tienda:</label>
        <input
          type="text"
          id="store-name"
          name="store-name"
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
          maxLength={50} // Límite de 50 caracteres
          placeholder="Ingrese el nombre de su tienda"
          required
        />

        <label htmlFor="description">Descripción de la Tienda:</label>
        <textarea
          id="description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          maxLength={300} // Límite de 300 caracteres
          placeholder="Ingrese una descripción de su tienda"
          required
        ></textarea>

        <label htmlFor="whatsapp">Número de WhatsApp:</label>
        <div className="phone-input">
          <div className="country-code-dropdown">
            <button
              type="button"
              className="dropdown-toggle"
              onClick={toggleDropdown}
            >
              {selectedCode}
            </button>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                {countryCodes.map((country) => (
                  <div
                    key={country.code}
                    className="dropdown-item"
                    onClick={() => handleCodeSelect(country.code)}
                  >
                    {country.country} ({country.code})
                  </div>
                ))}
              </div>
            )}
          </div>
          <input
            type="tel"
            id="whatsapp"
            name="whatsapp"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={`Ingrese su número (${selectedCode})`}
            required
          />
        </div>

        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </div>
  );
};

export default BecomeVendorScreen;
