import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './SidePanel.css';
import CountryData from '../../CountryData'; // Import CountryData

const SidePanel = ({ isOpen, onClose, onCountryClick }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleCountryClick = (coordinates) => {
    onCountryClick(coordinates); // Pass coordinates to update map center
    navigate('/map'); // Ensure navigation to the MapScreen
    onClose(); // Close the side panel
  };

  return (
    <div className={`side-panel ${isOpen ? 'open' : ''}`}>
      <div className="side-panel-header">
        <span>Choose a Country</span>
        <button className="close-btn" onClick={onClose}>
          X
        </button>
      </div>
      <div className="side-panel-content">
        <ul className="country-list">
          {CountryData.map((country) => (
            <li
              key={country.name}
              onClick={() => handleCountryClick(country.coordinates)} // Handle click
              className="country-item"
            >
              {country.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidePanel;
