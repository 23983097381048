import React from 'react';
import PropTypes from 'prop-types';
import './PopupCard.css';


const PopupCard = ({ name, description, imageUrl, onClick }) => {
  return (
    <div className="popup-card" onClick={onClick}>
      <img src={imageUrl} alt={name} className="popup-card-image" />
      <h3 className="popup-card-title">{name}</h3>
      <p className="popup-card-description">{description}</p>
    </div>
  );
};

PopupCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PopupCard;
