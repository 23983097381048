import React, { useState, useEffect } from 'react';
import './SearchBar.css';

const SearchBar = ({ vendors, setMapCenter }) => {
  const [searchQuery, setSearchQuery] = useState(''); // To hold the search input value
  const [filteredResults, setFilteredResults] = useState([]); // To hold filtered search results

  useEffect(() => {
    if (!vendors || vendors.length === 0) {
      setFilteredResults([]);
      return;
    }

    if (searchQuery.trim() === '') {
      setFilteredResults([]);
      return;
    }

    const results = vendors.filter((vendor) => {
      const englishKeywordsMatch = vendor.keywords?.english.some((keyword) =>
        keyword.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const spanishKeywordsMatch = vendor.keywords?.spanish.some((keyword) =>
        keyword.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const nameMatch = vendor.name.toLowerCase().includes(searchQuery.toLowerCase());

      return englishKeywordsMatch || spanishKeywordsMatch || nameMatch;
    });

    setFilteredResults(results);
  }, [searchQuery, vendors]);

  // Function to handle vendor click and set map center
  const handleResultClick = (coordinates) => {
    setMapCenter(coordinates); // Set the map center to vendor coordinates
    setSearchQuery(''); // Clear the search query after selecting a result
    setFilteredResults([]); // Clear filtered results after selecting a result
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        placeholder="Busca un lugar..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar-input"
      />
      {filteredResults.length > 0 && (
        <div className="search-results">
          {filteredResults.map((vendor, index) => (
            <div
              key={index}
              className="search-result-item"
              onClick={() => handleResultClick(vendor.location.coordinates)} 
            >
              {vendor.name} - {vendor.country}
            </div>
          ))}
        </div>
      )}
      {searchQuery && filteredResults.length === 0 && (
        <div className="no-results">
          No se encontraron resultados para "{searchQuery}"
        </div>
      )}
    </div>
  );
};

export default SearchBar;
