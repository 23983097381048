import React from 'react';
import { Link } from 'react-router-dom';
import VendorList from '../components/Vendor/VendorList'; // Import VendorList for carousel
import '../styles/HomePage.css';
import vendorHeroImage from '../images/vendor-hero.png'; // Import the actual image

const HomePage = () => {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Bienvenidos a CompraSi</h1>
          <p>Descubre y apoya a los vendedores locales en tu comunidad.</p>
          <Link to="/map" className="discover-button">Descubre Proveedores</Link>
        </div>
        <div className="hero-image">
          <img src={vendorHeroImage} alt="Hero Vendors" />
        </div>
      </section>

      {/* Vendor Carousel Section */}
      <section className="vendor-list-section">
        <h2>Proveedores Destacados</h2>
        <VendorList isCarousel={true} /> {/* Pass the carousel prop */}
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 CompraSi. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default HomePage;
