import React, { useState, useEffect } from 'react';
import MapboxExample from '../components/Map/MapboxExample';
import SidePanel from '../components/Map/SidePanel';
import SearchBar from '../components/Search/SearchBar';
import '../styles/MapScreen.css';

const MapScreen = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false); // State for side panel visibility
  const [mapCenter, setMapCenter] = useState(null); // State for map center coordinates
  const [zoomLevel, setZoomLevel] = useState(5); // State for zoom level
  const [vendors, setVendors] = useState([]); // State to store vendors

  // Fetch vendors from the API
  useEffect(() => {
    fetch(`/.netlify/functions/vendors`) // Update this endpoint if necessary
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setVendors(data); // Set vendors once fetched
      })
      .catch((error) => {
        console.error('Error fetching vendors:', error);
      });
  }, []);

  // Toggle the side panel
  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen); // Toggle the panel's visibility
  };

  // Handle country selection from the side panel
  const handleCountryClick = (coordinates) => {
    setMapCenter(coordinates); // Set the map center to the country's coordinates
    setZoomLevel(5); // Adjust zoom level for a full country view
    setIsPanelOpen(false); // Close the side panel
  };

  // Handle vendor selection from the search bar
  const handleSearchResultClick = ({ coordinates, zoom }) => {
    setMapCenter(coordinates); // Set the map center to the vendor's coordinates
    setZoomLevel(zoom || 18); // Use provided zoom level or default to street-level
  };

  return (
    <div className="map-screen">
      {/* Map */}
      <div className="map-container">
        <MapboxExample mapCenter={mapCenter} zoomLevel={zoomLevel} />
      </div>

      {/* Search Bar */}
      <div className="search-bar-container">
        <SearchBar
          vendors={vendors}
          setMapCenter={(coordinates) =>
            handleSearchResultClick({ coordinates, zoom: 18 })
          }
        />
      </div>

      {/* Side Panel */}
      <SidePanel
        isOpen={isPanelOpen}
        onClose={togglePanel}
        onCountryClick={handleCountryClick}
      />

      {/* Floating Burger Icon */}
      {!isPanelOpen && (
        <button
          className="floating-burger-btn"
          onClick={togglePanel}
          aria-label="Open Side Panel"
        >
          ☰
        </button>
      )}
    </div>
  );
};

export default MapScreen;
