import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StaticMap from '../Map/StaticMap';
import './StoreListings.css';
import whatsappLogo from '../../images/whatsapp-logo.png'; 
import wazeLogo from '../../images/waze-logo.png';

const StoreListings = () => {
  const { id } = useParams(); // Get the vendor ID from the URL params
  const [loading, setLoading] = useState(true); // State to handle loading
  const [vendor, setVendor] = useState(null); // State to store the vendor
  const [copied, setCopied] = useState(false); // State to track copy success

  // Fetch vendor data from your MongoDB API
  useEffect(() => {
    const fetchVendor = async () => {
      try {
        const response = await fetch(`/.netlify/functions/vendors?id=${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch vendor data');
        }
        const data = await response.json();
        setVendor(data); // Set the fetched vendor data
      } catch (error) {
        console.error('Error fetching vendor data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchVendor();
  }, [id]);

  if (loading) {
    return <p>Cargando detalles del proveedor..</p>;
  }

  if (!vendor) {
    return <p>Vendedor no encontrado</p>;
  }

  const { name, description, phoneNumber, products, location } = vendor; // Adjust according to your API response structure
  const coordinates = location?.coordinates || []; // Safeguard for coordinates

  // Function to copy store link to clipboard
  const copyToClipboard = () => {
    const storeLink = `${window.location.origin}/store/${id}`;
    navigator.clipboard.writeText(storeLink).then(() => {
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Revert back to default state after 2 seconds
    });
  };

  return (
    <div className="store-listings">
      <div className="store-details">
        <h2>{name}</h2>
        <p>{description}</p>

        {/* WhatsApp button */}
        <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
          <img src={whatsappLogo} alt="WhatsApp" className="whatsapp-button" />
        </a>



        {/* Waze button */}
        {coordinates.length === 2 && (
        <a href={`https://www.waze.com/ul?ll=${coordinates[1]},${coordinates[0]}&navigate=yes`} target="_blank" rel="noopener noreferrer" >
          <img src={wazeLogo} alt="Navigate with Waze" className="wazelogo-button" />
        </a> )}





        {/* Copy Store Link Button */}
        <div className="copy-button-container">
          <button onClick={copyToClipboard} className="copy-button">
            {copied ? (
              <>
                <span className="copy-checkmark">✔</span> ¡Copiado!
              </>
            ) : (
              <>
                <span className="copy-icon">📋</span> Copiar Link
              </>
            )}
          </button>
        </div>
      </div>

      <h3>Products</h3>
      <div className="product-grid">
        {/* Rendering products array */}
        {products?.length > 0 ? (
          products.map((product, index) => (
            <div key={index} className="product-item">
              <img src={product.image} alt={product.name} className="product-image" />
              <h4>{product.name}</h4>
              <p>{product.price}</p>
            </div>
          ))
        ) : (
          <p>No hay productos disponibles</p>
        )}
      </div>

      {/* Static Map at the bottom */}
      <StaticMap coordinates={coordinates} storeName={name} />
    </div>
  );
};

export default StoreListings;
