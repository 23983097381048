import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import App from './App';
import { initGA, trackPageView } from './analytics'; // Import analytics utilities

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

const Main = () => {
  const location = useLocation();

  useEffect(() => {
    initGA(trackingId); // Initialize Google Analytics
  }, []);

  useEffect(() => {
    trackPageView(location.pathname + location.search); // Track page views
  }, [location]);

  return <App />;
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Router>
    <Main />
  </Router>
);
