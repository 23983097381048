import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const StaticMap = ({ coordinates, storeName }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: coordinates, // Center the map on the store's location
      zoom: 14, // Zoom level focused on the store
      interactive: true, // Enable user interaction with the map
    });

    // Add a marker for the store
    new mapboxgl.Marker({ color: 'red' })
      .setLngLat(coordinates)
      .setPopup(new mapboxgl.Popup().setText(storeName)) // Optional: Add a popup with the store name
      .addTo(map);

    return () => map.remove(); // Cleanup the map instance when the component is unmounted
  }, [coordinates, storeName]);

  return (
    <div
      ref={mapContainerRef}
      style={{
        width: '100%',
        height: '300px',
        marginTop: '20px',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    />
  );
};

export default StaticMap;
