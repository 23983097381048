import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import VendorCard from './VendorCard'; // Ensure this is styled appropriately
import './VendorList.css';

const VendorList = ({ isCarousel = false }) => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const listRef = useRef(null); // Ref for the vendor list container

  // Fetch vendor data from the API
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await fetch(`/.netlify/functions/vendors`);
        if (!response.ok) throw new Error('Failed to fetch vendor data');

        const data = await response.json();
        setVendors(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching vendor data:', error);
        setError('Error fetching vendor data');
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  // Scroll left
  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  // Scroll right
  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  if (loading) {
    return <p>Loading vendors...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (vendors.length === 0) {
    return <p>No vendors available at the moment.</p>;
  }

  return (
    <div className={`vendor-list-container ${isCarousel ? 'carousel-mode' : ''}`}>
      {!isCarousel && <h1>Local Vendors</h1>} {/* Title for non-carousel view */}
      
      {isCarousel && (
        <button className="carousel-arrow left" onClick={scrollLeft}>
          &#8249;
        </button>
      )}

      <div className={`vendor-list ${isCarousel ? 'vendor-carousel' : ''}`} ref={listRef}>
        {vendors.map((vendor) => (
          <VendorCard
            key={vendor._id}
            name={vendor.name}
            description={vendor.description}
            imageUrl={vendor.imageUrl}
            onClick={() => (window.location.href = `/store/${vendor._id}`)} // Navigate to store
          />
        ))}
      </div>

      {isCarousel && (
        <button className="carousel-arrow right" onClick={scrollRight}>
          &#8250;
        </button>
      )}

      {!isCarousel && (
        <div className="vendor-button-container">
          <Link to="/">
            <button className="toggle-button">Mapa</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default VendorList;
