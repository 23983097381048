import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import PopupCard from './PopupCard';
import './MapboxExample.css'; // Import the CSS file

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapboxExample = ({ mapCenter, zoomLevel }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null); // Store the map instance
  const [vendors, setVendors] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  // Fetch vendor data from the API
  useEffect(() => {
    fetch(`/.netlify/functions/vendors`)
      .then((response) => response.json())
      .then((data) => {
        const geojsonVendors = {
          type: 'FeatureCollection',
          features: data.map((vendor) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: vendor.location.coordinates,
            },
            properties: {
              name: vendor.name,
              description: vendor.description,
              imageUrl: vendor.imageUrl,
              id: vendor._id,
            },
          })),
        };
        setVendors(geojsonVendors);
      })
      .catch((error) => console.error('Error fetching vendor data:', error));
  }, []);

  // Get user's current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, []);

  // Initialize Mapbox map
  useEffect(() => {
    if ((!vendors.features || vendors.features.length === 0) && !userLocation) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: userLocation || [-90.5069, 14.6349], // Default to Guatemala City
      zoom: userLocation ? 12 : 5, // Default zoom
    });

    mapRef.current = map;

    // Add user location marker
    if (userLocation) {
      new mapboxgl.Marker({ color: 'red' })
        .setLngLat(userLocation)
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setText('Usted está aquí'))
        .addTo(map);
    }

    map.on('load', () => {
      // Add vendor source and clustering
      map.addSource('vendors', {
        type: 'geojson',
        data: vendors,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points
        clusterRadius: 50, // Radius of each cluster
      });

      // Cluster layer
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'vendors',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      });

      // Cluster count layer
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'vendors',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      // Unclustered points layer
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'vendors',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      // Add click event for unclustered points
      map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { name, description, imageUrl, id } = e.features[0].properties;

        const popupNode = document.createElement('div');
        popupNode.className = 'popup-card-container';

        ReactDOM.render(
          <PopupCard
            name={name}
            description={description}
            imageUrl={imageUrl}
            onClick={() => (window.location.href = `/store/${id}`)}
          />,
          popupNode
        );

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setDOMContent(popupNode)
          .addTo(map);
      });

      // Add click event for clusters
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        const clusterId = features[0].properties.cluster_id;

        map.getSource('vendors').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        });
      });

      // Change cursor to pointer on hover
      map.on('mouseenter', 'clusters', () => (map.getCanvas().style.cursor = 'pointer'));
      map.on('mouseleave', 'clusters', () => (map.getCanvas().style.cursor = ''));
    });

    return () => map.remove(); // Clean up on component unmount
  }, [vendors, userLocation]);

  // Recenter and adjust zoom on mapCenter or zoomLevel change
  useEffect(() => {
    if (mapRef.current && mapCenter) {
      mapRef.current.flyTo({
        center: mapCenter,
        zoom: zoomLevel || 18, // Use provided zoomLevel or default to 18
        essential: true,
      });
    }
  }, [mapCenter, zoomLevel]);

  return <div className="mapbox-container" ref={mapContainerRef}></div>;
};

export default MapboxExample;
