const CountryData = [
    { name: "Argentina", coordinates: [-63.6167, -38.4161] },
    { name: "Belize", coordinates: [-88.4976, 17.1899] },
    { name: "Bolivia", coordinates: [-63.5887, -16.2902] },
    { name: "Brazil", coordinates: [-51.9253, -14.2350] },
    { name: "Chile", coordinates: [-71.5429, -35.6751] },
    { name: "Colombia", coordinates: [-74.2973, 4.5709] },
    { name: "Costa Rica", coordinates: [-84.0907, 9.7489] },
    { name: "Cuba", coordinates: [-77.7812, 21.5218] },
    { name: "Dominican Republic", coordinates: [-70.1627, 18.7357] },
    { name: "Ecuador", coordinates: [-78.1834, -1.8312] },
    { name: "El Salvador", coordinates: [-88.8965, 13.7942] },
    { name: "Guatemala", coordinates: [-90.5069, 14.6349] },
    { name: "Guyana", coordinates: [-58.9302, 4.8604] },
    { name: "Haiti", coordinates: [-72.2852, 18.9712] },
    { name: "Honduras", coordinates: [-86.2419, 14.6415] },
    { name: "Jamaica", coordinates: [-77.2975, 18.1096] },
    { name: "Mexico", coordinates: [-102.5528, 23.6345] },
    { name: "Nicaragua", coordinates: [-85.2072, 12.8654] },
    { name: "Panama", coordinates: [-80.7821, 8.5380] },
    { name: "Paraguay", coordinates: [-58.4438, -23.4425] },
    { name: "Peru", coordinates: [-75.0152, -9.1900] },
    { name: "Puerto Rico", coordinates: [-66.5901, 18.2208] },
    { name: "Suriname", coordinates: [-56.0278, 3.9193] },
    { name: "Trinidad and Tobago", coordinates: [-61.2225, 10.6918] },
    { name: "Uruguay", coordinates: [-55.7658, -32.5228] },
    { name: "Venezuela", coordinates: [-66.5897, 6.4238] }
  ];
  
  export default CountryData;
  